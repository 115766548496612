export interface Ref {
  name: string;
  objectId: string;
  url: string;
  creator: RefCreator;
}

export interface RefCreator {
  displayName: string;
  url: string;
}
export interface AzResponse<T> {
  count: number;
  value: T[];
}

export interface BranchStats {
  commit: Commit;
  name: string;
  aheadCount: number;
  behindCount: number;
  isBaseVersion: boolean;
}

export interface LightCommit {
  commitId: string;
  url: string;
}

export interface Commit extends LightCommit {
  author: Author;
  committer: Author;
  comment: string;
}

export interface Author {
  name: string;
  email: string;
  date: Date;
}

export interface PullRequestSummary {
  repository: Repository;
  pullRequestId: number;
  codeReviewId: number;
  status: PullRequestStatus;
  createdBy: PullRequestMember;
  creationDate: Date;
  title: string;
  description: string;
  sourceRefName: string;
  targetRefName: string;
  mergeStatus: PullRequestMergeStatus;
  isDraft: boolean;
  mergeId: string;
  lastMergeSourceCommit: LightCommit;
  lastMergeTargetCommit: LightCommit;
  lastMergeCommit: LightCommit;
  reviewers: Reviewer[];
  url: string;
  labels: PullRequestLabel[];
  supportsIterations: boolean;
}
export enum PullRequestStatus {
  abandoned = "abandoned",
  active = "active",
  all = "all",
  completed = "completed",
  notSet = "notSet",
}

export enum PullRequestMergeStatus {
  conflicts = "conflicts",
  failure = "failure",
  notSet = "notSet",
  queued = "queued",
  rejectedByPolicy = "rejectedByPolicy",
  succeeded = "succeeded",
}

export interface Repository {
  id: string;
  name: string;
  url: string;
  webUrl: string;
  project: Project;
}

export interface Project {
  id: string;
  name: string;
  state: string;
}

export interface PullRequestMember {
  id: string;
  displayName: string;
  uniqueName: string;
  url: string;
  imageUrl: string;
}

export interface Reviewer extends PullRequestMember {
  reviewerUrl: string;
  vote: number;
}

export interface PullRequestLabel {
  id: string;
  name: string;
  active: boolean;
}

// Projects

export enum ProjectState {
  all = "all", // All projects regardless of state.
  createPending = "createPending", // Project has been queued for creation, but the process has not yet started.
  deleted = "deleted", // Project has been deleted.
  deleting = "deleting", // Project is in the process of being deleted.
  new = "new", // Project is in the process of being created.
  unchanged = "unchanged", // Project has not been changed.
  wellFormed = "wellFormed", // Project is completely created and ready to use.
}

export enum ProjectVisibility {
  private = "private", // The project is only visible to users with explicit access.
  public = "public", // The project is visible to all.
}

export interface TeamProjectReference {
  abbreviation: string; // Project abbreviation.
  defaultTeamImageUrl: string; // Url to default team identity image.
  description: string; // The project's description (if any).
  id: string; // Project identifier.
  lastUpdateTime: string; // Project last update time.
  name: string; // Project name.
  revision: number; // Project revision.
  state: ProjectState; // Project state.
  url: string; // Url to the full version of the object.
  visibility: ProjectVisibility; // Project visibility.
}
