import { azureOrganizationUrl } from "./security";
import {
  AzResponse,
  BranchStats,
  PullRequestStatus,
  PullRequestSummary,
  Ref,
  TeamProjectReference,
} from "./Dto";
import { AzureRequestHelper } from "./AzureRequestHelper";

const noBranchId = "0000000000000000000000000000000000000000";

export class AzureRepository {
  project: string;
  name: string;
  private azure: AzureRequestHelper;

  constructor(
    project: string,
    repository: string,
    azureDevops: AzureRequestHelper
  ) {
    this.project = project;
    this.name = repository;
    this.azure = azureDevops;
  }

  async ListRefs(filter: string): Promise<AzResponse<Ref>> {
    const refs = await this.fetchAzDevopsForGit("refs", { filter }, {});
    return refs;
  }

  async getBranches(): Promise<TeamProjectReference[]> {
    const response = await this.azure.fetchAuthenticated(
      `${azureOrganizationUrl}/${this.project}/_apis/git/repositories/${this.name}/refs?api-version=6.0`,
      {}
    );

    const refs = (await response.json()).value;
    return refs;
  }

  async getBranchStats(branchName: string, base: string): Promise<BranchStats> {
    return await this.fetchAzDevopsForGit(
      "stats/branches",
      { name: branchName, "baseVersionDescriptor.version": base },
      {}
    );
  }

  async getPullRequestsTargetting(
    branchName: string
  ): Promise<AzResponse<PullRequestSummary>> {
    const pullRequests = await this.fetchAzDevopsForGit(
      "pullrequests",
      {
        "searchCriteria.targetRefName": `refs/heads/${branchName}`,
        "searchCriteria.status": PullRequestStatus.active,
      },
      {}
    );
    return pullRequests;
  }

  async getFileContent(path: string, versionDescriptor: string) {
    const response = await this.fetchRawAzDevopsForGit(
      "items",
      { path, "versionDescriptor.version": versionDescriptor },
      {}
    );

    return await response.text();
  }

  async createDraftPullRequest(
    fromBranch: string,
    toBranch: string,
    title: string,
    description: string
  ): Promise<PullRequestSummary> {
    return await this.fetchAzDevopsForGit(
      "pullrequests",
      { supportsIterations: "true" },
      {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          sourceRefName: `refs/heads/${fromBranch}`,
          targetRefName: `refs/heads/${toBranch}`,
          title,
          description,
          isDraft: true,
        }),
      }
    );
  }

  async deleteBranch(branchName: string) {
    const hash = await this.getObjectIdFromRef(branchName);
    return await this.updateBranch(branchName, hash, noBranchId);
  }

  async createBranch(newBranchName: string, fromBranch: string) {
    const hash = await this.getObjectIdFromRef(fromBranch);
    return await this.updateBranch(newBranchName, noBranchId, hash);
  }

  private async fetchAzDevopsForGit(
    endpoint: string,
    params: Record<string, string>,
    options: RequestInit
  ) {
    const response = await this.fetchRawAzDevopsForGit(
      endpoint,
      params,
      options
    );
    return await response.json();
  }

  private async fetchRawAzDevopsForGit(
    endpoint: string,
    params: Record<string, string>,
    options: RequestInit
  ) {
    const url = `${azureOrganizationUrl}/${
      this.project
    }/_apis/git/repositories/${this.name}/${endpoint}?${new URLSearchParams({
      ...params,
      "api-version": "6.0",
    })}`;
    return await this.azure.fetchAuthenticated(url, options);
  }

  private async updateBranch(
    branchName: string,
    oldCommitHash: string,
    newCommitHash: string
  ) {
    return await this.fetchAzDevopsForGit(
      "refs",
      {},
      {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify([
          {
            name: `refs/heads/${branchName}`,
            newObjectId: newCommitHash,
            oldObjectId: oldCommitHash,
          },
        ]),
      }
    );
  }

  private async getObjectIdFromRef(branchName: string) {
    const refResult = await this.ListRefs(`heads/${branchName}`);
    return refResult.value[0].objectId;
  }
}
