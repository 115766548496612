import { scopes, TokenCallback } from "./security";

export class AzureRequestHelper {
  private tokenCallback: TokenCallback;

  constructor(tokenCallback: TokenCallback) {
    this.tokenCallback = tokenCallback;
  }

  async fetchAuthenticated(
    url: string,
    options: RequestInit
  ): Promise<Response> {
    const response = await fetch(url, {
      ...options,
      headers: {
        authorization: `Bearer ${await this.tokenCallback(scopes)}`,
        ...options.headers,
      },
    });
    if (!response.ok)
      throw new Error(`Request failed with status ${response.status}`);
    return response;
  }
}
