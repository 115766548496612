import { azureOrganizationUrl } from "./security";
import { TeamProjectReference } from "./Dto";
import { AzureRequestHelper } from "./AzureRequestHelper";

export class AzureDevops {
  private azure: AzureRequestHelper;

  constructor(azure: AzureRequestHelper) {
    this.azure = azure;
  }
  async getProjects(): Promise<TeamProjectReference[]> {
    const response = await this.azure.fetchAuthenticated(
      `${azureOrganizationUrl}/_apis/projects?api-version=6.0`,
      {}
    );
    return (await response.json()).value;
  }

  async getRepositories(project: string): Promise<TeamProjectReference[]> {
    const response = await this.azure.fetchAuthenticated(
      `${azureOrganizationUrl}/${project}/_apis/git/repositories/`,
      {}
    );
    return (await response.json()).value;
  }
}
